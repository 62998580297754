import { useIsRequired } from '~/components/form';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form';
import { InputWithIcon } from '~/components/ui/input';
import { cn } from '~/libs/utils';

import type { FieldProps, HiddenProps, NumberProps, TextProps } from './types';

export type TextFieldProps = FieldProps & (TextProps | NumberProps | HiddenProps);

export const TextField = ({
  label,
  helpText,
  name,
  defaultValue,
  className,
  type,
  value,
  ...props
}: TextFieldProps & { value?: string }) => {
  const isRequired = useIsRequired(name);
  const isHidden = type === 'hidden';

  return (
    <FormField
      name={name}
      defaultValue={value ?? defaultValue}
      render={({ field }) => (
        <FormItem
          hidden={isHidden}
          aria-hidden={isHidden}
          className={cn(
            {
              hidden: isHidden,
              'opacity-60': isHidden,
              'cursor-not-allowed select-none [&_input]:cursor-not-allowed': isHidden,
            },
            className
          )}
        >
          <FormLabel>
            {label}
            {isRequired ? <span className='text-destructive'> *</span> : null}
          </FormLabel>
          <FormControl>
            <InputWithIcon {...props} {...field} type={type} />
          </FormControl>
          {helpText ? <FormDescription>{helpText}</FormDescription> : null}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
